<template>
  <div class="holder text-center mb-2 mb-md-0" :class="[bg, color, size]">
    <div class="row m-0 h-100 w-100 align-items-center" @click="isHover = !isHover">
      <div class="col-auto p-0 opacity">
        <slot />
      </div>
      <div class="col bold holder_font">{{placeholder}}</div>
      <div class="col-auto p-0" :class="{canRotate: isHover}">
        <slot />
      </div>
    </div>
    <!--DESKTOP-->
    <transition name="slideBottom">
      <div class="select_dropdown" v-if="isHover" @click.self="isHover = false">
        <div class="dropdown_inner">
          <div class="row mx-0 my-4 justify-content-center d-md-none">
            <div class="col-11 text-center title">
              {{title}}
            </div>
            <div class="close cursor" @click="isHover = false"><ios-close-icon class="inline-icon"/></div>
          </div>
          <div class="row mx-0 mt-2 justify-content-center scroll_inner">
            <div class="col-10 py-2 text-start" v-if="donorOptions.donor.size > 0" >
              Donor
            </div>
            <div class="col-10 py-2" v-for="(option, key) in donorOptions.donor" :key="key" >
              <div class="form-check medium" @click="selectOption(option)">
                <div class="row m-0 align-items-center">
                  <div class="col-auto p-0">
                    <input class="form-check-input" type="radio" name="option" :value="option[0]" :id="'donor' + key" >
                  </div>
                  <div class="col p-0">
                    <label class="form-check-label w-100 text-start" :for="'donor' + key">
                      {{option[1].userDetails.firstName}} {{option[1].userDetails.lastName}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10 py-2 text-start mt-2" v-if="donorOptions.organisations.size > 0" >
              Organisations
            </div>
            <div class="col-10 py-2" v-for="(option, key) in donorOptions.organisations" :key="key" >
              <div class="form-check medium" @click="selectOption(option)">
                <div class="row m-0 align-items-center">
                  <div class="col-auto p-0">
                    <input class="form-check-input" type="radio" name="option" :value="option[0]" :id="'org' + key">
                  </div>
                  <div class="col p-0">
                    <label class="form-check-label w-100 text-start" :for="'org' + key">
                      {{option[1].userDetails.registeredName}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10 py-2 text-start mt-2" v-if="donorOptions.groups.size > 0" >
              Groups
            </div>
            <div class="col-10 py-2" v-for="(option, key) in donorOptions.groups" :key="key" >
              <div class="form-check medium">
                <div class="row m-0 align-items-center">
                  <div class="col-auto p-0">
                    <input class="form-check-input" type="radio" name="option" :value="option[0]" :id="'group' + key" >
                  </div>
                  <div class="col p-0">
                    <label class="form-check-label w-100 text-start" :for="'group' + key">
                      {{option[1].name}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-0 my-3 justify-content-center">
            <div class="col-10">
              <Button color="green" width="100" btnText="Select" icon="arrow" @buttonClicked="applyFilter">
                <ios-checkmark-icon class="inline-icon" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!--END DESKTOP-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('./Button.vue')),
    'ios-checkmark-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'SelectDonor',
  props: ['placeholder', 'title', 'bg', 'color', 'size'],
  emits: ['update:modelValue', 'applyfilter'],
  data () {
    return {
      isHover: false,
      isFiltered: false,
      theOptions: []
    }
  },
  computed: {
    ...mapGetters([
      'userDonors'
    ]),
    donorOptions () {
      const arr = this.userDonors
      const result = {
        donor: {},
        organisations: {},
        groups: {}
      }
      const donor = new Map()
      const organisations = new Map()
      const groups = new Map()
      arr.forEach(el => {
        if (!el.organisationDonor) {
          const item = donor.get('don' + el.user.userID) || { id: 'don' + el.user.userID, userDetails: el.user, count: 0 }
          item.count++
          donor.set(item.id, item)
        } else {
          if (!el.organisation.archive) {
            const item = organisations.get('org' + el.organisation.organisationID) || { id: 'org' + el.organisation.organisationID, userDetails: el.organisation, count: 0 }
            item.count++
            organisations.set(item.id, item)
          }
        }
      })
      result.donor = donor
      result.organisations = organisations
      result.groups = groups
      return result
    }
  },
  methods: {
    applyFilter () {
      this.$emit('applyfilter', this.theOptions)
      this.isHover = false
    },
    selectOption (val) {
      this.theOptions = val
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .holder {
    padding: 12px 24px;
    line-height: 1;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
  }
  .small {
    border-radius: 2rem;
    font-size: 0.8rem;
    height: 2rem;
    padding: 0 0.75rem;
  }
  .holder_font {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .select_dropdown {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 60px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9;
    /* background-color: pink; */
  }
  .title {
    color: var(--green-color-dark);
    font-family: "quicksand_medium";
    font-weight: 600;
    font-size: 19px;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0.5rem;
    width: inherit;
  }
  .dropdown_inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 75vh;
    background-color: rgb(249, 250, 251);
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: rgb(51, 51, 51);
  }
  .scroll_inner {
    max-height: 350px;
    overflow-y: auto;
  }
  .form-control {
    background-color: #fff;
    color: var(--green-color);
    border-color: var(--green-color);
    border-radius: 2rem;
    font-size: 1rem;
    height: 2.5rem;
    font-weight: 500;
    text-align: left;
  }
  .slideBottom-enter-active {
    transition: all 0.5s ease;
  }
  .slideBottom-leave-active {
    transition: all 0.5s ease;
  }
  .slideBottom-enter-from {
    transform: translateY(100%);
    opacity: 1;
  }
  .slideBottom-leave-to {
    transform: translateY(100%);
    opacity: 1;
  }
  .canRotate .rotate {
    transform: rotate(90deg);
  }
  @media (min-width: 992px) {
    .select_dropdown {
      position: absolute;
      left: 0;
      bottom: inherit;
      top: 0;
      width: 100%;
      min-height: 60px;
      height: inherit;
      background-color: transparent;
      /* background-color: pink; */
    }
    .dropdown_inner {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: inherit;
      max-height: inherit;
      top: 50%;
      width: 100%;
      min-width: 250px;
      background-color: rgb(249, 250, 251);
      border-radius: 20px;
      color: rgb(51, 51, 51);
    }
    .holder {
      height: 40px;
      padding: 12px 48px 12px 32px;
      line-height: 1;
      border-radius: 100px;
      position: relative;
      cursor: pointer;
    }
    .small {
      border-radius: 2rem;
      font-size: 0.8rem;
      height: 2rem;
      padding: 0 0.75rem;
    }
    .slideBottom-enter-active {
      transition: all 0.5s ease;
    }
    .slideBottom-leave-active {
      transition: all 0.1s ease;
    }
    .slideBottom-enter-from {
      transform: translateY(0);
      opacity: 0;
    }
    .slideBottom-leave-to {
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
